import React from "react";
import { TextInput } from "react-native";
import {
  InputText as NativeInputText,
  InputTextProps,
} from "@clearabee/ui-library-native";
import { useFieldContext } from "components/Core/Form/FieldComponent";

export const InputText = React.forwardRef<TextInput, InputTextProps>(
  (props, ref): React.ReactElement => {
    const fieldContext = useFieldContext();

    return (
      <NativeInputText
        onSubmitEditing={() => {
          if (fieldContext && !!Object.keys(fieldContext).length) {
            fieldContext.submitForm();
          }
        }}
        ref={ref}
        {...props}
      />
    );
  },
);
