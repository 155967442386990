import React, { useContext, useMemo } from "react";
import {
  useForm as useHookForm,
  FieldValues,
  get,
  UnpackNestedValue,
  FormProvider,
  useFormContext as useReactFormHookContext,
  FormState,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FieldProps,
  FormProps,
  UseFormReturn,
  UseFormProps,
  UseFormContextState,
  FieldComponentType,
  FieldComponentProps,
} from "lib/formTypes";
import { FormComponent } from "components/Core/Form/FormComponent";
import { FieldComponent } from "components/Core/Form/FieldComponent";
import { useTranslation } from "react-i18next";
import { zodErrorMap } from "lib/zodErrorMap";
import { z } from "zod";
import { FormContext } from "./useFormContext";

export const getZodObjectProperty = (
  accessor: string,
  schema: z.ZodObject<any> | z.ZodEffects<any>,
): z.ZodObject<any> | z.ZodEffects<any> | undefined => {
  const keys = accessor.split(".");
  // eslint-disable-next-line
  let currentObj: any = schema;
  for (const key of keys) {
    currentObj =
      currentObj?.shape?.[key] ||
      (currentObj as unknown as z.ZodEffects<any>)?._def?.schema?.shape?.[key];
  }

  return currentObj;
};

/**
 * https://react-hook-form.com/advanced-usage#FormProviderPerformance
 */
const MemoedField = React.memo(
  ({
    formState,
    ...props
  }: FieldComponentProps & { formState: FormState<unknown> }) => (
    <FieldComponent {...props} />
  ),
  (prev, next) =>
    prev.formState.isDirty === next.formState.isDirty &&
    prev.error === next.error &&
    prev.children === next.children,
);

/**
 * useForm Field component. Purposely not exported as it
 * is given through the Form Component.
 */
const Field = (props: FieldProps): React.ReactElement => {
  const [translate, i18n] = useTranslation();
  const {
    control,
    formState,
    formState: { errors },
  } = useReactFormHookContext();
  const { validationSchema } = useContext(FormContext);
  const { required: userRequired, name } = props;
  const error: string | undefined = get(errors, name)?.message;

  const required = useMemo<boolean>(() => {
    if (typeof userRequired === "boolean") return userRequired;
    if (!validationSchema) return false;

    const property = getZodObjectProperty(
      props.name,
      validationSchema as unknown as z.ZodObject<any>,
    );

    return !property?.isOptional();
  }, [validationSchema, userRequired]);

  return (
    <MemoedField
      formState={formState}
      {...props}
      {...{
        required,
        error: error
          ? `${i18n.exists(error) ? translate(error) : error}`
          : undefined,
        control,
      }}
    />
  );
};

export const useForm = <
  ZodOutput,
  ZodInput extends FieldValues,
  TFieldValues extends ZodInput = ZodInput,
  TContext = any,
>({
  onSubmit,
  validationSchema,
  ...props
}: UseFormProps<ZodOutput, ZodInput, TFieldValues, TContext>): UseFormReturn<
  TFieldValues,
  TContext
> => {
  const [translate] = useTranslation();
  const { handleSubmit, ...useFormHook } = useHookForm({
    mode: "onTouched",
    resolver: validationSchema
      ? (zodResolver(validationSchema, {
          errorMap: zodErrorMap(translate),
        }) as any)
      : undefined,
    ...props,
  });

  const formContext = {
    ...useFormHook,
    validationSchema,
    handleSubmit,
    submitForm: handleSubmit((values) =>
      onSubmit?.(
        values as unknown as UnpackNestedValue<ZodOutput>,
        useFormHook,
      ),
    ),
  };

  return formContext;
};

export const Form = <TFieldValues extends FieldValues = FieldValues>({
  children,
  state,
  useForm = true,
}: FormProps<TFieldValues>): React.ReactElement => {
  return (
    <FormProvider {...state}>
      <FormContext.Provider value={{ ...state, Field } as UseFormContextState}>
        <FormComponent submitForm={state.submitForm} useForm={useForm}>
          {typeof children === "function"
            ? children({
                Field: Field as FieldComponentType<TFieldValues>,
              })
            : children}
        </FormComponent>
      </FormContext.Provider>
    </FormProvider>
  );
};
