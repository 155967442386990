import React from "react";
import { FieldValues } from "react-hook-form";
import { FormComponentProps } from "lib/formTypes";

export const FormComponent = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
>({
  submitForm,
  children,
  useForm = true,
}: FormComponentProps<TFieldValues, TContext>): React.ReactElement => {
  if (useForm) return <form onSubmit={submitForm}>{children}</form>;
  return <>{children}</>;
};
