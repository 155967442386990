import { createContext, useContext } from "react";
import { FieldValues } from "react-hook-form";
import { UseFormContextState } from "lib/formTypes";

export const FormContext = createContext<UseFormContextState>(
  {} as UseFormContextState,
);

export const useFormContext = <
  TFieldValues extends FieldValues = FieldValues,
>(): UseFormContextState<TFieldValues> => {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error("Cannot use FormContext outside of a form");
  }

  return context as UseFormContextState<TFieldValues>;
};
