import { TFunction } from "react-i18next";
import { z } from "zod";

export const zodErrorMap =
  (translate: TFunction): z.ZodErrorMap =>
  (issue, context) => {
    switch (issue.code) {
      case z.ZodIssueCode.too_small: {
        if (issue.minimum === 1) {
          return {
            message: translate("common.form.errors.requiredField"),
          };
        }

        return {
          message: translate("common.form.errors.tooShortField", {
            minimum: issue.minimum,
          }),
        };
      }

      case z.ZodIssueCode.too_big: {
        return {
          message: translate("common.form.errors.tooLongField", {
            maximum: issue.maximum,
          }),
        };
      }

      case z.ZodIssueCode.invalid_string: {
        if (issue.validation === "email") {
          return {
            message: translate("common.form.errors.invalidEmail"),
          };
        }
      }

      case z.ZodIssueCode.invalid_literal: {
        return {
          message: translate("common.form.errors.mustBeChecked"),
        };
      }
    }

    return { message: context.defaultError };
  };
