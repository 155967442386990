import React from "react";
import {
  InputDate as WebInputDate,
  InputDateProps,
  theme,
} from "@clearabee/ui-library";

export const InputDate = ({
  styles,
  ...props
}: InputDateProps): React.ReactElement => {
  const color = props.color ?? "brand";

  return (
    <WebInputDate
      {...props}
      styles={[
        {
          ".UI-DayPicker-Month": {
            borderCollapse: "separate",
            borderSpacing: "2px",
            display: "block",
          },
          ".UI-DayPicker-Day": {
            boxSizing: "border-box",
            border: `1px solid transparent`,
          },
          ".UI-DayPicker-Day:not(.UI-DayPicker-Day--outside)": {
            transition: "background 0.2s",
            ":hover": {
              backgroundColor: `${theme.colors[color].lighter} !important`,
            },
          },
          ".UI-DayPicker-Day--disabled:not(.UI-DayPicker-Day--outside)": {
            border: `1px solid ${theme.colors.greyscale.lighter}`,
            borderRadius: theme.spacing.xsmall2,
            color: theme.colors.greyscale.lighter,
            background: `linear-gradient(135deg, #ffffff00 49%, ${theme.colors.greyscale.lighter} 49%, ${theme.colors.greyscale.lighter} 51%,  #ffffff00 51%)`,
          },

          ".UI-DayPicker-Day:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside)":
            {
              backgroundColor: theme.colors[color].lightest,
              borderRadius: theme.spacing.xsmall2,
            },
          ".UI-DayPicker-Day--selected::after": {
            backgroundColor: theme.colors[color].base,
            borderRadius: `${theme.spacing.xsmall2} !important`,
          },
          [`.UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside).start, .UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside).end`]:
            {
              borderRadius: `${theme.spacing.xsmall2} !important`,
              backgroundColor: `${theme.colors[color].base} !important`,

              "::after": {
                backgroundColor: `${theme.colors[color].base} !important`,
              },
            },
          "input::placeholder": {
            color: theme.colors.greyscale.lightest,
          },
        },
        styles,
      ]}
    />
  );
};
