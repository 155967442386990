import React, { createContext, useContext } from "react";
import { Controller, FieldValues, Path } from "react-hook-form";
import { View } from "react-native";
import { Message, Text } from "@clearabee/ui-library-native";
import { FieldComponentProps, UseFormContextState } from "lib/formTypes";
import { styles } from "./FieldComponent.styles";
import { useFormContext } from "lib/hooks/useFormContext";

type FieldComponentContextState = UseFormContextState;

export const FieldComponentContext = createContext(
  {} as FieldComponentContextState,
);

export const useFieldContext = (): FieldComponentContextState | undefined =>
  useContext(FieldComponentContext);

export const FieldComponent = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
  label,
  required,
  children,
  name,
  styles: userStyles,
  labelStyles,
  errorBackground,
  errorStyles,
  error,
  invertErrorColors,
  ...props
}: FieldComponentProps<TFieldValues, TName>): React.ReactElement => {
  const formState = useFormContext();
  return (
    <FieldComponentContext.Provider value={formState}>
      <View style={[styles.field, userStyles]}>
        {label && (
          <Text styles={[styles.label, labelStyles]}>
            {label}
            {required && "*"}
          </Text>
        )}
        <Controller
          {...props}
          render={({ field: { ref, ...field }, ...props }) => {
            return children({ ...props, field: { ...field, error } });
          }}
          name={name}
        />
        {error && (
          <Message
            type="error"
            color={invertErrorColors ? "light" : undefined}
            background={errorBackground}
            styles={[
              styles.message,
              invertErrorColors && styles.invertErrorColors,
              errorStyles,
            ]}
            small
          >
            {error}
          </Message>
        )}
      </View>
    </FieldComponentContext.Provider>
  );
};
