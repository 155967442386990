import { StyleSheet } from "react-native";
import { theme } from "@clearabee/ui-library-native";

export const styles = StyleSheet.create({
  label: {
    ...theme.fontDefaults.small,
    marginBottom: theme.spacing.xsmall2,
  },
  message: {
    marginTop: theme.spacing.xsmall2,
  },
  invertErrorColors: {
    backgroundColor: theme.colors.negative.base,
  },
  field: {
    width: "100%",
    marginVertical: theme.spacing.small,
  },
});
