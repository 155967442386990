import React from "react";
import {
  InputRangedDate as WebInputRangedDate,
  InputRangedDateProps,
  theme,
} from "@clearabee/ui-library";

export const InputRangedDate = ({
  styles,
  ...props
}: InputRangedDateProps): React.ReactElement => {
  return (
    <WebInputRangedDate
      {...props}
      styles={[
        {
          ".UI-DayPicker-Month": {
            borderCollapse: "separate",
            borderSpacing: "2px",
            display: "block",
          },
          ".UI-DayPicker-Day": {
            boxSizing: "border-box",
            border: `1px solid transparent`,
          },
          ".UI-DayPicker-Day:not(.UI-DayPicker-Day--outside)": {
            transition: "background 0.2s",

            ":hover": {
              backgroundColor: `${theme.colors.brand.lighter} !important`,
            },
          },
          ".UI-DayPicker-Day:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside)":
            {
              backgroundColor: theme.colors.brand.lightest,
              borderRadius: theme.spacing.xsmall2,
            },
          ".UI-DayPicker-Day--disabled:not(.UI-DayPicker-Day--outside)": {
            border: `1px solid ${theme.colors.greyscale.lighter}`,
            borderRadius: theme.spacing.xsmall2,
            color: theme.colors.greyscale.lighter,
            background: `linear-gradient(135deg, #ffffff00 49%, ${theme.colors.greyscale.lighter} 49%, ${theme.colors.greyscale.lighter} 51%,  #ffffff00 51%)`,
          },
          ".UI-DayPicker-Day--selected::after": {
            backgroundColor: theme.colors.brand.base,
            borderRadius: `${theme.spacing.xsmall2} !important`,
          },
          ".UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--outside)": {
            backgroundColor: `${theme.colors.brand.base}CC !important`,
            borderRadius: `${theme.spacing.xsmall2} !important`,
            color: `${theme.colors.light.base} !important`,
          },
          ".UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--outside):hover": {
            backgroundColor: `${theme.colors.brand.base}CC !important`,
          },
          "input::placeholder": {
            color: theme.colors.greyscale.lighter,
          },
        },
        styles,
      ]}
    />
  );
};
