import React from "react";
import {
  InputSelect as UIInputSelect,
  InputSelectProps,
  InputSelectRef,
} from "@clearabee/ui-library";

export const InputSelect = React.forwardRef<InputSelectRef, InputSelectProps>(
  (
    { usePortal, ...props }: InputSelectProps & { usePortal?: boolean },
    ref,
  ) => {
    return (
      <UIInputSelect
        {...props}
        menuPortalTarget={
          usePortal ? document.getElementById("select-portal") : undefined
        }
        ref={ref}
      />
    );
  },
);
